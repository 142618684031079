const NUTRITION_ALLERGEN_CONSTANTS = {
  SEARCHOURMENU: 'searchMenu',
  CAROUSAL: 'carousalComponent',
  KFC_MENU_TITLE: 'KFC MENU',
  NUTRITION_ALLERGEN_ACTION_RESPONSE: 'nutritionAllergenDisclaimerReducer.response',
  PERCENT_DAILY_VALUES_TEXT: 'PercentDailyValuesText', //'*Percent Daily Values (DV) are based on a 2,000 calorie diet.'
  INFORMATION_CORRECT_TEXT: 'infoCorrectText', //Information correct as at March 2020
};

export default NUTRITION_ALLERGEN_CONSTANTS;

export const MENU_PLP_CONSTANT = {
  PRODUCT_VIEW_TEXT: 'view',
  NAV_TITLE: 'menuTitle',
  CARD_TYPE: {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
    CAROUSAL: 'carousal',
  },
  SERVICE_CHANNEL: 'web',
};
export const CLEAR_ALL = 'clearAll';
export const CLEAR_SEARCH = 'clearSearch';
export const YOUR_SEARCH_TEXT = 'yourSearch';
export const TURNED_NO_RESULTS_TEXT = 'turnedNoResult';
export const RETURNED_NO_RESULTS = 'returnedNoResults';
export const BACK_TO_MENU = 'backToMenu';
export const WE_FOUND_TEXT = 'we Found';
export const SEARCH_OUR_MENU_TEXT = 'Search our menu';

export const RESULTS = 'results';
export const CATEGORY_ID = 'CAT86';
export const ENTER = 'Enter';
export const NUTRITION_ALLERGEN_GUIDE = 'nutration-allergen-guide'; //'NUTRITION & ALLERGEN GUIDE'
export const KFC_FOOD = 'kfcFood'; // 'KFC FOOD'
export const ENERGY_KJ = 'eneryKJText'; //'Energy (KJ)'
export const KFC_BANNER_IMAGE = 'kfc-banner-image';
export const THATS_ALL_FOLKS = 'thatsAllFolks';// That’s all folks
export const MORE_INFO = 'moreInfo';// More Info'
export const VIEW_UP = 'viewUp';
export const AVERAGE_SERVING_SIZE = "Average serving size";
export const ENERGY = "Energy";
export const SODIUM = "Sodium";
export const RNA_NUTRITION_ALLERGEN = '/nutrition-allergen/true';
export const TOPIC_QUESTION = 'topicQuestion';
export const STATIC_BRAND_PAGE = 'staticBrandPage';
export const NUTRITION_ALLERGEN_GUIDE_BOTTOM = 90;


