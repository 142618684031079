import CartNotificationSuccessImage from '../../../../styles/staticAssets/images/cart-notification-success.png';
import {
  updateItemQuantityAction,
  removeLineItemAction,
  removeAllItemAction,
  setUserLastActiveTime,
  removeCouponAction
} from '@kfc-global/react-shared/redux/Actions/CartAction';
import { quantityIncreaseDecreaseFromCart, logAddToCartEvent, logRemoveToCartEvent } from 'organisms/MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import { CART_PAGE_CONSTANTS } from '../../Constants/CartPageConstants';
import { getPayloadForRemoveFromCart,setAnalyticsData } from 'organisms/CartPage/Utils/cartPageUtils';
import { translateWithI18Next } from '@kfc-global/react-shared/i18n/i18N';
import { fetchItemDetailsAction } from '@kfc-global/react-shared/redux/Actions/MenuAction';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { MENU_PDP_PAGE_CONSTANTS } from 'organisms/MenuPDPPage/Constants/MenuPDPPageConstants';
import { toast } from 'react-toastify';
import ImageComponent from 'atoms/Image';
import { ROUTE_URL } from 'common/constants/SharedConstants';
const { QUANTITY_ACTION, TOAST_NOTIFICATION, ANALYTICS_EVENTS, CART_ALERT } = CART_PAGE_CONSTANTS;
const { MINI_PDP_DATA, FROMFLAGEDIT, EDIT_CLICKED } = START_ORDER_CONSTANTS;
const { RESET_MODGROUP_IDS_UPDATE } = MENU_PDP_PAGE_CONSTANTS;
export default function cartItemListHandlers(
  cartSummary,
  tenantId,
  cartItems,
  productMapById,
  userOrderStateDispatch,
  dispatch,
  history,
  userType =''
) {
  const itemQuantityHandler = (item, action) => {
    let { quantity, foodLineId } = item;
    const { cartId, discountAmounts, totalAmount, foodLines, itemListName } = cartSummary ?? {};
    if (quantity && foodLineId && cartId && tenantId) {
      quantity =
        action === QUANTITY_ACTION.Increase
          ? quantity + 1
          : action === QUANTITY_ACTION.Decrease
          ? quantity - 1
          : quantity;
      dispatch(
        updateItemQuantityAction(
          {
            params: {
              basketId: cartId,
              basketLineItemId: foodLineId,
            },
            tenantId: tenantId,
          },
          { quantity },
        ),
      );
      dispatch(setUserLastActiveTime(new Date()));
    }
    const event = action === QUANTITY_ACTION.Increase ? ANALYTICS_EVENTS.ADD_TO_CART : ANALYTICS_EVENTS.REMOVE;
    const itemObj = getPayloadForRemoveFromCart([{...item, foodLines, itemListName}], productMapById, CART_PAGE_CONSTANTS.QUANTITY_MODIFIED, discountAmounts);
    const { item_variant, ...updatedItemObj } = itemObj?.[0] ?? [];
    if (action === QUANTITY_ACTION.Increase) {
      const totalValue = itemObj?.[0]?.price * itemObj?.[0]?.quantity;
      logAddToCartEvent(userType, itemObj, +totalValue);
    }
    else {
      logRemoveToCartEvent(event, itemObj);
    }
  };

  const addToCartAnalyticsOnly =(item, addOrRemove, currencyConversionFactor = 1) =>{
    const event = addOrRemove === QUANTITY_ACTION.Increase ? ANALYTICS_EVENTS.ADD_TO_CART : ANALYTICS_EVENTS.REMOVE;
    let itemObj = getPayloadForRemoveFromCart([item], productMapById, CART_PAGE_CONSTANTS.QUANTITY_MODIFIED, currencyConversionFactor);
    quantityIncreaseDecreaseFromCart(event,itemObj);
  };

  const itemEditHandler = item => {
    const { foodItemId: productId = '', customizable = false, quantity, foodLineId } = item;
    const isItemAddedFromPLPModel = cartItems?.itemLocations.find(i => i.itemid === productId);
    const categoryId = productId.split('-')[0];
    const { catalog } = cartSummary;
    userOrderStateDispatch({ type: RESET_MODGROUP_IDS_UPDATE });
    userOrderStateDispatch({
      type: EDIT_CLICKED,
      value: { cartEditClicked: true },
    });
    userOrderStateDispatch({
      type: MINI_PDP_DATA,
      value: { id: productId, flag: FROMFLAGEDIT, foodLineId: foodLineId },
    });
    dispatch(
      fetchItemDetailsAction({
        tenantId: tenantId,
        catalogName: catalog,
        itemId: productId,
      }),
    );
    setAnalyticsData(ANALYTICS_EVENTS.EDIT);
    dispatch(setUserLastActiveTime(new Date()));
  };

  const itemRemoveHandler = (item, currencyConversionFactor = 1) => {
    let { foodLineId, flag = '' } = item;
    const { cartId } = cartSummary;
    if (foodLineId && cartId && tenantId) {
      dispatch(
        removeLineItemAction({
          tenantId,
          basketId: cartId,
          basketLineItemId: foodLineId,
        }),
      );
      if (flag !== 'specialItems') {
        toast.dark(
          <div className='img-text-div'>
            <ImageComponent srcFile={CartNotificationSuccessImage} />{' '}
            <span className='text'>{translateWithI18Next(TOAST_NOTIFICATION.ITEM_REMOVED)}</span>
          </div>,
          {
            bodyClassName: 'sign-in-toast',
            hideProgressBar: true,
            position: toast.POSITION.BOTTOM_CENTER,
          },
        );
        setTimeout(() => {
          toast.dismiss();
        }, 4000);
      }
    }
    let itemObj = getPayloadForRemoveFromCart([item], productMapById, CART_PAGE_CONSTANTS.REMOVE_ALL_QUANTITY, currencyConversionFactor);
    quantityIncreaseDecreaseFromCart(ANALYTICS_EVENTS.REMOVE,itemObj);
    dispatch(setUserLastActiveTime(new Date()));
  };

  const itemAllRemoveHandler = () => {
    showCartAlert(true);
  };

  const itemAllRemoveAction = items => {
    const { cartId } = cartSummary;
    if (cartId && tenantId) {
      dispatch(
        removeAllItemAction({
          basketId: cartId,
          tenantId,
        }),
      );
    }
    showCartAlert(false);
    let itemObj = getPayloadForRemoveFromCart(items, productMapById, CART_PAGE_CONSTANTS.REMOVE_ALL_QUANTITY);
    quantityIncreaseDecreaseFromCart(ANALYTICS_EVENTS.REMOVE,itemObj);
    dispatch(setUserLastActiveTime(new Date()));
  };

  const showCartAlert = showCartAlert => {
    userOrderStateDispatch({
      type: CART_ALERT,
      value: { showCartAlert },
    });
  };

  const addMoreMenuHandler = () => {
    history.push({
      pathname: ROUTE_URL.MENU_PAGE,
    });
    dispatch(setUserLastActiveTime(new Date()));
  };

  return {
    itemQuantityHandler,
    itemRemoveHandler,
    itemAllRemoveHandler,
    itemAllRemoveAction,
    addMoreMenuHandler,
    itemEditHandler,
    showCartAlert,
    addToCartAnalyticsOnly,
  };
}