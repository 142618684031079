import { ROUTE_URL, LOCAL_STORAGE_KEYS, TO_CATERING_NAV, TO_MENU_NAV, DISPOSITION_KEYS } from 'common/constants/SharedConstants';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { deleteBasketsAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import { resetMenuFulfillmentData } from '@kfc-global/react-shared/redux/Actions/MenuAction';
import { setUserParams } from '@kfc-global/react-shared/redux/Actions/AppStateAction';
import { isCateringDisposition } from './utils';
import { STORES } from 'organisms/SearchStore/AU/Config/searchStore.config';

export const getDefaultDispositionData = (data) => {
  const {
    path,
    genericMenuName,
    genericCateringName,
    defaultdisposition,
    defaultcateringdisposition
  } = data;
  if (checkIfCateringPage(path)) {
    return { defaultdispostitionType: defaultcateringdisposition, defaultMenuName: genericCateringName };
  }
  else {
    return { defaultdispostitionType: defaultdisposition, defaultMenuName: genericMenuName };
  }
}

export const checkIfCateringPage = (path) => {
  return path?.indexOf(ROUTE_URL.CATERING_PAGE) !== -1;
}

export const checkIfMenuPLPPDPPage = (path) => {
  return path.indexOf(ROUTE_URL.MENU_PAGE) !== -1;
}

export const ifCateringNavigationHappened = (localized, dispositionType, pathname) => {
  let result = false;
  if (localized){
    if(!isCateringDisposition(dispositionType) && checkIfCateringPage(pathname)){
      result = TO_CATERING_NAV;
    }
    else if(isCateringDisposition(dispositionType) && checkIfMenuPLPPDPPage(pathname)){
      result = TO_MENU_NAV;
    }
  }
  return result;
  }

export const CateringMenuNavigationHandler = (cartId, tenantId, userParams, dispatch, userOrderStateDispatch, showCateringDisposition, dispositionNew, flag) => {
  const {
    SCHEDULE_ORDER_DATA,
    DISPOSITION_METHOD,
    SCHEDULE_ORDER_TIME_DATA,
    SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    SET_DISPOSITION,
    USER_LOCALIZED,
    CURRENT_STORE_INFO,
    DISPOSITION_DATA,
    SEARCH_ORDER_DATA
  } = START_ORDER_CONSTANTS;
  const {
    DISPOSITION_TYPE,
    DISPOSITION_NEW,
    CURRENT_STORE_INFO_STORAGE,
    STORE_INFO,
    DISPOSITION_OLD,
    DATE_TIME_ORDER,
    SAVED_TIME_ORDER
  } = LOCAL_STORAGE_KEYS;
  if (cartId) {
    dispatch(
      deleteBasketsAction({ basketId: cartId }, false, {
        data: {},
        tenantId,
      }),
    );
  }
  userParams && dispatch(
    setUserParams({
      ...userParams,
      localized: false,
    }),
  );
  userOrderStateDispatch({
    type: USER_LOCALIZED,
    value: { isLocalized: false },
  });
  let dispositionDefaultData = {
    type: '',
    store: '',
    date: '',
    time: '',
    interval: '',
    deliveryAddress: '',
    deliveryAddressLine: '',
    selectedTime: '',
  }
  userOrderStateDispatch({
    type: SET_DISPOSITION,
    value: {
      oldvalue: dispositionNew?.type?.toUpperCase() === DISPOSITION_KEYS.DELIVERY ? {...dispositionDefaultData, type: DISPOSITION_KEYS.DELIVERY} : dispositionDefaultData,
      newValue: dispositionDefaultData,
    },
  });
  localStorage.removeItem(DISPOSITION_OLD);
  localStorage.removeItem(DISPOSITION_NEW);
  
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_DATA,
    value: { selectedDateTime: {} },
  });
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    value: {},
  });
  localStorage.removeItem(DATE_TIME_ORDER);
  localStorage.removeItem(SAVED_TIME_ORDER);
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_DATA,
    value: {
      showScheduleOrderCatering: false,
      selectedStoreInfo: {},
      changeFlow: '',
      isAddressFlow: false,
      deliveryAddress: {},
      deliveryAddressLine: ''
    },
  });
  userOrderStateDispatch({
    type: DISPOSITION_METHOD,
    value: { dispositionType: '' },
  });
  localStorage.removeItem(DISPOSITION_TYPE);
  localStorage.removeItem(STORE_INFO);
  localStorage.removeItem(CURRENT_STORE_INFO_STORAGE);
  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: {},
  });
  dispatch(resetMenuFulfillmentData());
   //for flag false value open the disposition option
   if (!flag) {
    if (showCateringDisposition) {
      userOrderStateDispatch({ type: SEARCH_ORDER_DATA, value: { showSearchStore: true, configSearchScreen: STORES } });
      userOrderStateDispatch({
        type: DISPOSITION_METHOD,
        value: { dispositionType: DISPOSITION_KEYS.CATERING },
      });
      localStorage.setItem(LOCAL_STORAGE_KEYS.CONFIG_SEARCH_SCREEN, STORES);
    }
    else {
      userOrderStateDispatch({ type: DISPOSITION_DATA, value: { showDisposition: true } });
    }
  }
}