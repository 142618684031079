import { translateWithI18Next } from '@kfc-global/react-shared/i18n/i18N';
import ImageComponent from 'atoms/Image';
import React, { useEffect, useContext } from 'react';
import { ButtonType, ContainedButton } from 'atoms/Buttons';
import { PopUpView } from '../../../../organisms/MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import FoodItemListHandlers from 'organisms/CartPage/Presentation/ReusableComponents/FoodItemListHandlers';
import { UserOrderContext } from 'context/context';
import { getTernaryValue } from 'common/utilities/utils';
function AlertForm({
  contentMsg = '',
  title = '',
  splitTitle,
  imageSrc,
  altText,
  confirmButtonText = '',
  confirmHandler,
  cancelButtonText = '',
  cancelHandler,
  buttonContainerClass = '',
  imageClassName = '',
  alertLocation = '',
  secondaryButtonType,
  contentImg
}) {
  const { userOrderStateDispatch } = useContext(UserOrderContext);
  useEffect(() => {
    if (splitTitle) {
      PopUpView({
        popupTitle:
          translateWithI18Next(splitTitle.MSGTXT1) +
          translateWithI18Next(splitTitle.MSGTXT2) +
          translateWithI18Next(splitTitle.MSGTXT3),
      });
    } else if (contentMsg) {
      PopUpView({ popupTitle: contentMsg });
    } else if (!splitTitle) {
      PopUpView({ popupTitle: title });
    }
  }, [title, contentMsg]);
  useEffect(() => {
    return () => {
      const { showCartAlert } = FoodItemListHandlers(
        undefined,
        undefined,
        undefined,
        undefined,
        userOrderStateDispatch,
        undefined,
        undefined,
      );
      showCartAlert(false);
    };
  }, [userOrderStateDispatch]);
  return (
    <div className='alert-model-view-root' data-testid='alert-form'>
      {imageSrc ? <ImageComponent className={imageClassName} data-testid='alert-form-image' srcFile={imageSrc} alt={altText} /> : null}
      {splitTitle ? (
        <div className='alert-title' data-testid='split-msg'>
          <span>{translateWithI18Next(splitTitle.MSGTXT1)}</span>
          <span className='alert-red-text'>{translateWithI18Next(splitTitle.MSGTXT2)}</span>
          <span>{translateWithI18Next(splitTitle.MSGTXT3)}</span>
        </div>
      ) : (
        <span className='alert-title' data-testid='alert-form-title'>
          {title}
        </span>
      )}

      {contentMsg ? (
        <span className={`alert-content ${alertLocation}`} data-test-id='alert-form-content'>
          {contentMsg}
        </span>
      ) : null}
      
      {contentImg && (
        <ImageComponent className={imageClassName} data-testid='alert-form-image' srcFile={contentImg} alt={altText} /> 
      )}

      <div className={`alert-action-container ${buttonContainerClass}`}>
        {cancelHandler && <ContainedButton
          ariaLable=''
          data-testid='alert-cancel-button'
          variant={ButtonType.tertiary}
          onClickHandler={cancelHandler}
        >
          {cancelButtonText}
        </ContainedButton>}
        {confirmHandler && <ContainedButton
          ariaLable=''
          data-testid='alert-confirm-button'
          variant={getTernaryValue(secondaryButtonType, secondaryButtonType, ButtonType.secondary)}
          onClickHandler={confirmHandler}
        >
          {confirmButtonText}
        </ContainedButton>}
      </div>
    </div>
  );
}

export default AlertForm;
